import React from "react";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import Img from "gatsby-image";
import xgdevice from "../../images/xgdevice.png";
import lbtesting from "../../images/lab-testing.png";
import smonitoring from "../../images/service-monitoring.png";
import bmarking from "../../images/benchmarking.png";
import dtesting from "../../images/deployment-testing.png";
import lDeployments from "../../images/Lab-Deployments.png";
import pdevice from "../../images/portal-and-device.png";
import { graphql } from "gatsby";

const Xgautomation = (props) => {
  return (
    <Layout bodyClass="products xg-automation-platform ">
      <Seo
        title="XG Automation Platform"
        description="Deploying a cellular technology takes a lot of testing. After
            deployment it takes a lot of monitoring. XG Automation Platform
            (XGP) saves time and money by providing a comprehensive framework
            for mobile technology end-to-end test automation."
      />
      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
          />
        </div>

        <div className="container">
          <div className="row hero-section">
            <div className="col-12 wrap-page-title">
              <h1 className="page-title">XG Automation Platform</h1>
              <h2 className="page-subtitle">
                Making cellular testing efficient
              </h2>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row section-1">
          <div className="col-12">
            Deploying a cellular technology takes a lot of testing. After
            deployment it takes a lot of monitoring. XG Automation Platform
            (XGP) saves time and money by providing a comprehensive framework
            for mobile technology end-to-end test automation.
          </div>
        </div>
        <div className="row section-2">
          <div className="col-12">
            <div className="title-1">End-to-End Testing with XGP</div>
            <div className="detail">
              End to end testing covers validation of a capability from the
              origination of the service to the consumption of the service,
              covering the complete chain of technologies. With end to end
              testing a technician or a support team member can identify a
              problem with a service which might be caused by any component in
              the technology chain.
            </div>
          </div>
        </div>
        <div className="row section-3">
          <div className="col-md-12 col-lg-8 p-0 p-md-2">
            <div className="left">
              <div className="mb-3">
                XGP provides an all in one platform with a comprehensive set of
                test capabilities over different RF technologies:
              </div>
              <div className="list">
                <div>
                  RF Technologies: <span>2G, 3G, 4G, 5G</span>
                </div>
                <div>
                  Testing Capabilities:{" "}
                  <span>
                    Voice, VoLTE, SMS, STK, Broadband Data, Narrowband Data
                  </span>
                </div>
                <div>
                  Protocols:{" "}
                  <span>
                    TCP/IP, UDP, HTTP, TLS/DTLS, CoAP, MQTT, LWM2M and more
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 p-0 p-md-2">
            <div className="right">
              <div class="t1">
                Get in touch with us for detailed specifications
              </div>
              <div className="col-12">
                {" "}
                <a className="btn btn-primary" href="/contact/">
                  CONTACT US
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row section-4">
          <div className="col-12">
            <div className="text-center">
              <img alt="Device" className="fluid" src={xgdevice} />
              <div className="utext">How it Works</div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row section-5">
          <div className="col-12">
            <div className="t2">
              XGP consists of programmable GSM radios and a distributed test
              automation framework. With that, it provides an extremely agile
              and cost-effective framework for many types of testing required
              for network capabilities or applications in a mobile network.
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row section-6">
          <div className="col-12">
            <div className="title-1">For Network Operators</div>
            <div className="detail">
              There are many use cases that network operators can benefit from
              the XGP
            </div>
          </div>
        </div>
        <div className="row section-7">
          <div className="col-12 col-md-3">
            <div className="wrap">
              <div>
                <img alt="lab testing" src={lbtesting} />
              </div>
              <div className="ntitle">Lab Testing</div>
              <div className="para">
                In faraday caged lab setups, XGP makes testing and
                troubleshooting activities easy by providing a wide range of
                test capabilities powered by a single test platform
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="wrap">
              <div>
                <img alt="Service Monitoring" src={smonitoring} />
              </div>
              <div className="ntitle">Service Monitoring</div>
              <div className="para">
                For network services or applications that require an SLA, XGP is
                a reliable service monitor to monitor service levels and making
                alters in case of anomalies.
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="wrap">
              <div>
                <img alt="Benchmarking" src={bmarking} />
              </div>
              <div className="ntitle">Benchmarking</div>
              <div className="para">
                XGP makes easier service benchmarking either in specific
                location or in drive tests by fully automating the test
                scenarios and logging service parameters
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="wrap">
              <div>
                <img alt="testing" src={dtesting} />
              </div>
              <div className="ntitle">Deployment Testing</div>
              <div className="para">
                When production cells are provisioned, XGP allows repetitive
                test activities faster and cost effective
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row section-8">
          <div className="col-12 p-4">
            <div>
              <img className="img-fluid" alt="Deployment" src={lDeployments} />
            </div>
            <div className="utext">How it's Used</div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row section-9">
          <div className="col-12 sec-top">
            <div className="title-1">For Network Testing Providers</div>
            <div className="detail">
              If you are a network testing provider who engages in validating
              and certifying a cellular deployment, XGP provides many
              capabilities to reduce effort and streamline the process.
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="text-center">
              <img
                className="img-fluid text-md-center"
                alt="XGP Web Portal and Device"
                src={pdevice}
              />
            </div>
            <div className="utext">XGP Web Portal and Device</div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="l-detail">
              <span>Automate Test Scenarios: </span>Develop all test scenarios
              in a test engagement as organized test suites. Reuse and execute
              scripted test cases saving time and effort.
            </div>
            <div className="l-detail">
              <span>Multichannel Test Automation: </span>XGP supports RF
              Testing, Web Application Testing, API Testing and Mobile App
              testing with different test agents. You may develop complex test
              cases that span across multiple channels thanks to the
              multi-channel capability
            </div>
            <div className="l-detail">
              <span>Remote Testing: </span>XGP has it’s built in Distributed
              Test Coordinator. Your test team does not necessarily require to
              be present in the test lab to carry out testing
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid section-10">
        <div className="row">
          <div className="col-12 col-md-8 sec-top">
            <div className="title-2">Want to Know More?</div>
            <div className="detail">
              We have helped many network providers in multiple countries to
              accelerate their cellular deployments. Also, we have supported
              ongoing application monitoring and SLA compliance needs with a
              fraction of the cost.
            </div>
          </div>
          <div className="col-12 col-md-4 sec-bt">
            <div className="col-12 m-auto">
              {" "}
              <a className="btn btn-primary" href="/contact/">
                CONTACT US
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "tower.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default Xgautomation;
